<template>
  <div v-loading="loading">
    <el-card shadow="never" :body-style="{ padding: '20px' }">
      <div slot="header">
        <span class="iconfont icon-Commodity">&nbsp;商品资料</span>
      </div>
      <div class="card_header_flex">
        <div>
          <el-button type="primary" size="small" @click="dialogAddCommodity=true">新增商品</el-button>
          <el-button
            size="small"
            plain
            @click="onSubmitBatchDel"
            v-show="dataLineSelections.length>0"
          >批量删除</el-button>
        </div>
        <div>
          <el-select
            v-model="search"
            placeholder="请选择商品分类"
            clearable
            size="small"
            @change="initLoad"
          >
            <el-option
              v-for="item in optionsCategory"
              :key="item.id"
              :label="item.cnName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <el-table
        ref="table"
        :data="list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        size="mini"
        @selection-change="selectionLineChangeHandle"
        row-key="id"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
        <el-table-column prop="sort" label="排序" width="100">
          <template slot-scope="scope">
            <el-input v-model="scope.row.sort" size="mini"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="imgUrl" label="商品图片">
          <template slot-scope="scope">
            <el-image
              :src="fileUrl+scope.row.imgUrl"
              style="width: 100px; height: 100px;background:rgba(235,235,235,0.5);"
              fit="contain"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="cnName" label="商品中文名称"></el-table-column>
        <el-table-column prop="enName" label="商品英文名称"></el-table-column>
        <el-table-column prop="price" label="单价" width="100"></el-table-column>
        <el-table-column prop="inventory" label="库存" width="100"></el-table-column>
        <el-table-column prop="salesVolume" label="销量" width="100"></el-table-column>
        <el-table-column prop="categoryName" label="类目"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="openEdit(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="onSubmitDelCommodity(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page_right">
        <el-pagination
          background
          layout="total,prev, pager, next ,jumper"
          :total="list.length"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-card>

    <!-- 添加商品 -->
    <el-dialog
      :visible.sync="dialogAddCommodity"
      :close-on-click-modal="false"
      width="30%"
      @closed="closeDialogAddCommodity"
    >
      <span slot="title">新增商品</span>
      <el-form
        :model="form"
        ref="form"
        label-width="80px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item label="商品图片">
          <el-dropdown placement="bottom">
            <div>
              <div class="avatar-uploader-logo">
                <i class="el-icon-plus avatar-uploader-logo-icon" v-if="!form.imgUrl"></i>
                <el-image
                  v-else
                  :src="fileUrl+form.imgUrl"
                  style="width: 100px; height: 100px;background:rgba(235,235,235,0.5);cursor: pointer;"
                  fit="contain"
                ></el-image>
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="dialogAddFileUpload=true"
                style="padding:0"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
              <el-dropdown-item style="padding:0">
                <el-upload
                  style="text-align:center"
                  :action="actionUrl"
                  accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                  :headers="header"
                  :data="{type:0,category:'图片'}"
                  :show-file-list="false"
                  :on-success="handleAddSuccess"
                  :before-upload="beforeAddUpload"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <el-form-item
          label="名称"
          prop="cnName"
          :rules="{ required: true, message: '请输入名称', trigger: 'blur' }"
        >
          <el-input v-model="form.cnName" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item
          label="单价"
          prop="price"
          :rules="[{ required: true, message: '请输入单价', trigger: 'blur' },{ pattern:/(^[1-9][0-9]{0,7}$)|(^((0\.0[1-9]$)|(^0\.[1-9]\d?)$)|(^[1-9][0-9]{0,7}\.\d{1,2})$)/,message: '请输入正确的价格格式',trigger: 'blur'}]"
        >
          <el-input v-model="form.price" placeholder="请输入单价"></el-input>
        </el-form-item>

        <el-form-item
          label="库存"
          prop="inventory"
          :rules="[{ required: true, message: '请输入库存', trigger: 'blur' },{ pattern:/^\d{1,}$/,message: '请输入正整数',trigger: 'blur'}]"
        >
          <el-input v-model="form.inventory" placeholder="请输入库存"></el-input>
        </el-form-item>
        <el-form-item
          label="分类"
          prop="categoryId"
          :rules="{ required: true, message: '请选择商品分类', trigger: 'change' }"
        >
          <el-select
            v-model="form.categoryId"
            placeholder="请选择商品分类"
            size="small"
            style="width:100%"
          >
            <el-option
              v-for="item in optionsCategory"
              :key="item.id"
              :label="item.cnName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddCommodity=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddCommodity">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加窗口图片 -->
    <el-dialog
      :visible.sync="dialogAddFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'图片'" v-if="dialogAddFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogAddFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitAddCommodityImg" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改商品 -->
    <el-dialog :visible.sync="dialogEditCommodity" :close-on-click-modal="false" width="30%">
      <span slot="title">编辑商品</span>
      <el-form
        :model="editForm"
        ref="editForm"
        label-width="80px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item label="商品图片">
          <el-dropdown placement="bottom">
            <div>
              <div class="avatar-uploader-logo">
                <i class="el-icon-plus avatar-uploader-logo-icon" v-if="!editForm.imgUrl"></i>
                <el-image
                  v-else
                  :src="fileUrl+editForm.imgUrl"
                  style="width: 100px; height: 100px;background:rgba(235,235,235,0.5);cursor: pointer;"
                  fit="contain"
                ></el-image>
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="dialogEditFileUpload=true"
                style="padding:0"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
              <el-dropdown-item style="padding:0">
                <el-upload
                  style="text-align:center"
                  :action="actionUrl"
                  accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                  :headers="header"
                  :data="{type:0,category:'图片'}"
                  :show-file-list="false"
                  :on-success="handleEditSuccess"
                  :before-upload="beforeEditUpload"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <el-form-item
          label="名称"
          prop="cnName"
          :rules="{ required: true, message: '请输入名称', trigger: 'blur' }"
        >
          <el-input v-model="editForm.cnName" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item
          label="单价"
          prop="price"
          :rules="[{ required: true, message: '请输入单价', trigger: 'blur' },{ pattern:/(^[1-9][0-9]{0,7}$)|(^((0\.0[1-9]$)|(^0\.[1-9]\d?)$)|(^[1-9][0-9]{0,7}\.\d{1,2})$)/,message: '请输入正确的价格格式',trigger: 'blur'}]"
        >
          <el-input v-model="editForm.price" placeholder="请输入单价"></el-input>
        </el-form-item>
        <el-form-item
          label="库存"
          prop="inventory"
          :rules="[{ required: true, message: '请输入库存', trigger: 'blur' },{ pattern:/^\d{1,}$/,message: '请输入正整数',trigger: 'blur'}]"
        >
          <el-input v-model="editForm.inventory" placeholder="请输入库存"></el-input>
        </el-form-item>
        <el-form-item
          label="分类"
          prop="categoryId"
          :rules="{ required: true, message: '请选择商品分类', trigger: 'change' }"
        >
          <el-select
            v-model="editForm.categoryId"
            placeholder="请选择商品分类"
            size="small"
            style="width:100%"
          >
            <el-option
              v-for="item in optionsCategory"
              :key="item.id"
              :label="item.cnName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditCommodity=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditCommodity">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改窗口图片 -->
    <el-dialog
      :visible.sync="dialogEditFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'图片'" v-if="dialogEditFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditCommodityImg" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getCommodityApi,
  addCommodityApi,
  editCommodityApi,
  delCommodityApi,
  getMallCategoryApi
} from "../../../api/data";
import FileUpload from "../../../components/FileUpload";
import Aes from "../../../utils/aes";
export default {
  components: {
    FileUpload
  },
  data() {
    return {
      loading: true,
      search: "", //分类下拉查询条件
      currentPage: 1, //当前页
      pageSize: 10, //一页显示多少条
      list: [],
      optionsCategory: [], //下拉框商品分类
      form: {
        imgUrl: "",
        cnName: "",
        price: "",
        inventory: "",
        categoryId: ""
      },
      dataLineSelections: [], //表格选中集合
      dialogAddCommodity: false, //添加商品窗口
      dialogAddFileUpload: false, //添加商品--添加图片窗口
      editForm: {},
      dialogEditCommodity: false, //修改商品窗口
      dialogEditFileUpload: false //修改商品--修改图片窗口
    };
  },
  computed: {
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    }
  },
  created() {
    this.initLoad();
    this.initLoadCategory();
  },
  methods: {
    //初始化商品
    initLoad() {
      getCommodityApi({
        groupId: this.placesInfo.groupId,
        categoryId: this.search
      }).then(res => {
        this.loading = false;
        this.list = res.data;
      });
    },
    //初始化商品分类
    initLoadCategory() {
      getMallCategoryApi({ groupId: this.placesInfo.groupId }).then(res => {
        this.optionsCategory = res.data;
      });
      0;
    },
    //提交添加商品
    onSubmitAddCommodity() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.imgUrl == "") {
            this.$message.warning("请上传商品图片");
            return false;
          }
          addCommodityApi(this.form).then(res => {
            this.$message.success(res.msg);
            this.initLoad();
            this.dialogAddCommodity = false;
          });
        } else {
          return false;
        }
      });
    },
    //添加窗口关闭事件
    closeDialogAddCommodity() {
      this.$refs["form"].resetFields();
    },
    //图片窗口关闭事件
    closeDialogFileUpload() {
      this.$store.commit("setFileList", []);
    },
    //添加商品图片
    onSubmitAddCommodityImg() {
      this.dialogAddFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.form.imgUrl = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },
    //上传完成事件
    handleAddSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.form.imgUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
       this.initLoad();
    },
    //上传前事件
    beforeAddUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //打开修改窗口
    openEdit(row) {
      this.editForm = { ...row };
      this.dialogEditCommodity = true;
    },
    //提交修改商品
    onSubmitEditCommodity() {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          editCommodityApi(this.editForm).then(res => {
            this.$message.success(res.msg);
            this.initLoad();
            this.dialogEditCommodity = false;
          });
        } else {
          return false;
        }
      });
    },
    //修改窗口添加商品图片
    onSubmitEditCommodityImg() {
      this.dialogEditFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.editForm.imgUrl = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },
    //上传完成事件
    handleEditSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.editForm.imgUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
       this.initLoad();
    },
    //上传前事件
    beforeEditUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //删除商品
    onSubmitDelCommodity(row) {
      this.$confirm("此操作将永久删除该商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        const mallCommodity = [];
        mallCommodity.push(row.id);
        delCommodityApi({ ids: mallCommodity }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      });
    },
    //批量删除商品
    onSubmitBatchDel() {
      this.$confirm("此操作将永久删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delCommodityApi({ ids: this.dataLineSelections }).then(res => {
          this.$message.success(res.msg);
          this.initLoad();
          this.$refs.table.clearSelection();
        });
      });
    },
    //表格多选框选中事件
    selectionLineChangeHandle(val) {
      const selectionList = [];
      for (let i = 0; i < val.length; i++) {
        selectionList.push(val[i].id);
      }
      this.dataLineSelections = selectionList;
    },
    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    }
  }
};
</script>
<style lang='scss'>
.avatar-uploader-logo {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.avatar-uploader-logo:hover {
  border-color: #5e72f9;
}
.avatar-uploader-logo-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
</style>